/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table, Tabs, Grid, Modal, Tooltip } from "antd";
import { env } from "../configs/EnvironmentConfig";
import axios from "axios";
import Filter from "./filter";
import { General_Constants } from "../constants/GeneralConstant";
import styles from "./stats.module.scss";
import log from "../assets/images/log.png";
import loc from "../assets/images/loc.png";
import ApiConfig from "../configs/ApiConfig";
import Utils from "../utils";

const Standings = () => {
  const { useBreakpoint } = Grid;
  const [current, setCurrent] = useState("standings");
  const [isDraft, setIsDraft] = useState(false);
  const [draft, setDraft] = useState({});

  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const imgurl = process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
    ? "https://tcblbasketball.s3.ca-central-1.amazonaws.com"
    : "https://leagueofchamps.s3.ca-central-1.amazonaws.com";

  const [settings, setsettings] = useState({});
  const getaccountsettings = async () => {
    try {
      const response = await axios.get(
        `${env?.API_ENDPOINT_URL}/${ApiConfig.website.getaccountsettings}`
      );
      const v1 = {};
      response?.data?.[0]?.divisions?.forEach((item) => {
        v1[item.divisionid] = item.label;
      });
      const v2 = {};
      response?.data?.[0]?.leagues?.forEach((item) => {
        v1[item.leagueid] = item.label;
      });
      setsettings({
        leagues: { ...v2 },
        divisions: { ...v1 },
        logo: response?.data?.[0]?.logo,
      });
      // form.setFieldsValue({homepage:response?.data?.[0]?.homepage,
      //   home:response?.data?.[0]?.hiddenmenu?.includes("/home"),
      //   rule:response?.data?.[0]?.hiddenmenu?.includes("/rule"),
      //   portal:response?.data?.[0]?.portal,
      //   rulelink:response?.data?.[0]?.rule
      // })
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    getaccountsettings();
    //fetchplayersregistered()
  }, []);
  const image = process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
    ? log
    : loc;

  // const data = useSelector(state => state)
  // console.log(data, "datafgh");
  const [showPlayerstats, setshowPlayerstats] = useState(false);
  const [playerStats, setPlayerStats] = useState([]);
  console.log(playerStats, "playerStatsplayerStats");
  const getteamplayerstats = async (team) => {
    console.log(team, "team");
    const leagueid = leaguesList?.find((item) => item.name === league);
    const { data } = await axios.post(
      `${env?.API_ENDPOINT_URL}/settings/getTeamPlayerStats`,
      {
        tier: tier,
        season: season,
        league: leagueid.value,
        team: team,
      }
    );
    setshowPlayerstats(true);
    setPlayerStats([...data]);
  };
  const c1 = {
    "Thursday League": "6289f76ebd1d158a386ea893",
    "Sunday League": "626fad6d75280628c46c18c8",
    "Saturday League": "626fa5a98a5c29264288c85a",
    "Wednesday League": "62823b02b63039209ef70d5b",
    "Tuesday League": "6289f769bd1d158a386ea88c",
    "Monday League": "6289f762bd1d158a386ea885",
    "Friday League": "6289f773bd1d158a386ea89a",
  };
  const standingsColumnsTitleStyle = isMobile
    ? {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        backgroundColor: "#FFF",
        textAlign: "center",
      }
    : {
        color: "#000000",
        fontSize: "14px",
        fontWeight: "700",
        backgroundColor: "#FFF",
        textAlign: "center",
      };
  const standingsColumns = [
    {
      title: (
        <div
          style={
            isMobile
              ? {
                  color: "#000",
                  fontSize: "13px",
                  fontWeight: "700",
                  textAlign: "left",
                  paddingLeft: "10px",
                }
              : {
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "700",
                  textAlign: "left",
                  paddingLeft: "10px",
                }
          }
        >
          {General_Constants.TEAM}
        </div>
      ),
      fixed: "left",
      width: isMobile ? "70px" : "30%",
      render: (record, text, k) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "left",
                  fontWeight: "300",
                  color: "#000",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: "10px",
                }
              : {
                  textAlign: "left",
                  fontWeight: "500",
                  color: "#000000",
                  paddingLeft: "10px",
                  fontSize: isMobile ? "12px" : undefined,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
          }
        >
          <span
            style={{
              width: isMobile ? "20px" : "30px",
              display: "inline-block",
            }}
          >
            {k + 1}
          </span>
          <img
            src={
              Boolean(record?.logo)
                ? imgurl + record?.logo
                : settings?.logo || image
            }
            alt="mainlogo"
            style={{
              height: "25px",
              marginRight: "2px",
              marginLeft: "8px",
              fontSize: "14px",
              width: "25px",
            }}
          />
          <span
            style={{ color: "#FF0000" }}
            onClick={() => getteamplayerstats(record?.team)}
          >
            {record?.name?.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      title: <div style={standingsColumnsTitleStyle}></div>,
      width: isMobile ? "30px" : "10%",
      //width: "35px",
      key: "tier",
      dataIndex: "tier",
      render: (text, record) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "10px",
                }
              : {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: isMobile ? "12px" : undefined,
                }
          }
        >
          {settings?.divisions?.[text] ?? text}
        </div>
      ),
    },
    // { title: "Team", key: "name", width: "180px", dataIndex: "name" },
    {
      title: (
        <div style={standingsColumnsTitleStyle}>{General_Constants.GP}</div>
      ),
      width: isMobile ? "15px" : "10%",
      //width: "35px",
      key: "GP",
      dataIndex: "gp",
      render: (text, record) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "10px",
                }
              : {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: isMobile ? "12px" : undefined,
                }
          }
        >
          {parseInt(text ?? 0)}
        </div>
      ),
    },
    {
      title: (
        <div style={standingsColumnsTitleStyle}>{General_Constants.W}</div>
      ),
      width: isMobile ? "10px" : "10%",
      key: "wons",
      dataIndex: "wons",
      render: (text) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "10px",
                }
              : {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: isMobile ? "12px" : undefined,
                }
          }
        >
          {text}
        </div>
      ),
    },
    {
      title: (
        <div style={standingsColumnsTitleStyle}>{General_Constants.L}</div>
      ),
      //width: "35px",
      width: isMobile ? "10px" : "10%",
      key: "loss",
      dataIndex: "loss",
      render: (text, record) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "10px",
                }
              : { textAlign: "center", fontWeight: "500", color: "#000000" }
          }
        >
          {parseInt(record?.gp ?? 0) - parseInt(record?.wons ?? 0)}
        </div>
      ),
    },
    {
      title: (
        <span style={standingsColumnsTitleStyle}>{General_Constants.PCT}</span>
      ),
      width: isMobile ? "20px" : "10%",
      //width: "35px",
      key: "pct",
      dataIndex: "pct",
      render: (text) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "10px",
                }
              : { textAlign: "center", fontWeight: "500", color: "#000000" }
          }
        >
          {text ? parseFloat(text ?? 0).toFixed(3) : 0}
        </div>
      ),
    },
    // { title: "STRK", key: "streak", dataIndex: "streak" },
    {
      title: (
        <div style={standingsColumnsTitleStyle}>{General_Constants.PF}</div>
      ),
      width: isMobile ? "15px" : "10%",
      //width: "35px",
      key: "pf",
      dataIndex: "pf",
      render: (text) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "10px",
                }
              : { textAlign: "center", fontWeight: "500", color: "#000000" }
          }
        >
          {text}
        </div>
      ),
    },
    {
      title: (
        <div style={standingsColumnsTitleStyle}>{General_Constants.PA}</div>
      ),
      width: isMobile ? "15px" : "10%",
      //width: "35px",
      key: "pa",
      dataIndex: "pa",
      render: (text) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "10px",
                }
              : { textAlign: "center", fontWeight: "500", color: "#000000" }
          }
        >
          {text}
        </div>
      ),
    },
    {
      title: (
        <div style={standingsColumnsTitleStyle}>{General_Constants.DIFF}</div>
      ),
      width: isMobile ? "15px" : "10%",
      //width: "35px",
      key: "diff",
      dataIndex: "diff",
      render: (text) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "10px",
                }
              : { textAlign: "center", fontWeight: "500", color: "#000000" }
          }
        >
          {text}
        </div>
      ),
    },
  ];
  const standingsColumnsregistered = [
    {
      title: (
        <div
          style={
            isMobile
              ? {
                  color: "#000",
                  fontSize: "13px",
                  fontWeight: "700",
                  textAlign: "left",
                  paddingLeft: "10px",
                }
              : {
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "700",
                  textAlign: "left",
                  paddingLeft: "10px",
                }
          }
        >
          Order {General_Constants.TEAM}
        </div>
      ),
      fixed: "left",
      width: isMobile ? "70px" : "30%",
      render: (record, text, k) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "left",
                  fontWeight: "300",
                  color: "#000",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingLeft: "10px",
                }
              : {
                  textAlign: "left",
                  fontWeight: "500",
                  color: "#000000",
                  paddingLeft: "10px",
                  fontSize: isMobile ? "12px" : undefined,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
          }
        >
          {k + 1} &nbsp; &nbsp;
          <img
            src={
              Boolean(record?.logo)
                ? imgurl + record?.logo
                : settings?.logo || image
            }
            alt=""
            style={{
              height: "25px",
              marginRight: "2px",
              marginLeft: "15px",
              fontSize: "14px",
              width: "25px",
            }}
          />
          <span
            style={{ color: "#FF0000" }}
            onClick={() => getteamplayerstats(record?.team)}
          >
            {record?.name?.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      title: <div style={standingsColumnsTitleStyle}>Roster</div>,
      width: isDraft ? "0px" : isMobile ? "18px" : "10%",
      key: "roster",
      dataIndex: "roster",
      render: (text, record) => (
        <>
          {isDraft ? (
            <></>
          ) : (
            <div
              style={
                isMobile
                  ? {
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "10px",
                    }
                  : {
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#000000",
                      fontSize: isMobile ? "12px" : undefined,
                    }
              }
            >
              {console.log(record, "recordrecordrecord")}
              {record?.players?.filter(
                (item) =>
                  // ?.filter(
                  //     (ite) => ite?.season === season && ite?.league === c1[league]
                  //   )
                  item?.enrolled_season?.length
              )?.length
                ? "Complete"
                : "Incomplete"}
            </div>
          )}
        </>
      ),
    },
    {
      title: <div style={standingsColumnsTitleStyle}>Division</div>,
      width: isMobile ? "30px" : "10%",
      //width: "35px",
      key: "tier",
      dataIndex: "tier",
      render: (text, record) => (
        <div
          style={
            isMobile
              ? {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000",
                  fontSize: "10px",
                }
              : {
                  textAlign: "center",
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: isMobile ? "12px" : undefined,
                }
          }
        >
          {settings?.divisions?.[text] ?? text ?? "Not Assigned"}
        </div>
      ),
    },
  ];
  const standingsColumnsregisteredplayers = [
    {
      title: (
        <div className={isMobile ? styles.stage : styles.dataindex}>Player</div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: isMobile ? "100px" : "150px",
      key: "name",
      render: (record, stats) => (
        <div
          onClick={() => getteamplayerstats(stats.team)}
          className={isMobile ? styles.property : styles.module}
        >
          {stats.first_name} {stats.last_name}
        </div>
      ),
    },
    {
      title: <span>#</span>,
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "50px" : "50px",
      render: (record, stats) => (
        <div>
          <span className={styles.stagemob}>
            <span className={styles.point}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>GP</span>,
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "50px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>W</span>,
      dataIndex: "w",
      key: "w",
      width: isMobile ? "50px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>L</span>,
      dataIndex: "l",
      key: "l",
      width: isMobile ? "50px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.pts - b.pts,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>FGM</span>,
      dataIndex: "fgm",
      width: "70px",
      key: "fgm",
      sorter: (a, b) => a.fgm - b.fgm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fgm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FGA</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a.fga - b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fga}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FG%</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a?.fgm / a?.fga - b?.fgm / b?.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PM</span>,
      width: "70px",
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] - b["2ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2ptm"]) ? record["2ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PA</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2pta"] - b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"]) ? record["2pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2P%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / a["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PM</span>,
      width: "70px",
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] - b["3ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3ptm"]) ? record["3ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PA</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3pta"] - b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"]) ? record["3pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3P%</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: "70px",
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.ftm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTA</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.fta - b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fta}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FT%</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.ftm / b.fta - b.ftm / b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: "70px",
      sorter: (a, b) => a.oreb - b.oreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: "70px",
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.reb - b.reb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.ast - b.ast,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.stl - b.stl,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.blk - b.blk,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>TO</span>,
      dataIndex: "to",
      key: "to",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.to - b.to,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.pf - b.pf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      width: isMobile ? "50px" : "50px",
      sorter: (a, b) => a.tf - b.tf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
  ];

  const [teamsInfo, setteamsInfo] = useState([]);
  //Various List of Filters
  const [totalleaguedata, setTotalleaguedata] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [seasonList, setSeasonList] = useState([]);
  const [tiersList, setTiersList] = useState([]);

  //Various Selected Filter
  const [league, setLeague] = useState("Sunday League");
  const [tier, setTier] = useState("All");
  const [season, setSeason] = useState("");

  //Final Table data
  const [tableData, settableData] = useState([]);
  const [loader, setloader] = useState(false);
  const [rankingDataSource, setRankingDataSource] = useState([]);

  const [playersRegistered, setplayersRegistered] = useState([]);
  const fetchplayersregistered = async () => {
    const datab = await axios
      .post(`${env?.API_ENDPOINT_URL}/settings/fetchplayers`, {
        curseason: season,
        season: season,
        league: c1[league],
        tier: "All",
        leaderstype: "pts",
      })
      .catch((err) => {
        console.log("cannot fetch player");
      });
    const v3 = datab?.data?.data?.map((item) => {
      return { ...item, ...item.players?.[0] };
    });
    if (v3) {
      setplayersRegistered([...v3]);
    }
  };
  useEffect(() => {
    fetchplayersregistered();
  }, [season, league]);

  const getSeasonStandingsbyTier = async () => {
    let url = `${env?.API_ENDPOINT_URL}/settings/getNewStandings`;
    const leagueid =
      leaguesList?.find((item) => item.name === league)?.value ??
      leaguesList?.[0]?.value;

    setloader(true);
    await axios
      .post(url, { league: leagueid, tier, season })
      .then(async (res) => {
        let registered_teams = [];
        let registered_teamss = [];
        let registered_teamsr = [];
        let registered_teamssr = [];
        const cloneDummy = {
          diff: 0,
          gp: 0,
          logo: null,
          name: "",
          opppts: 0,
          pa: 0,
          pct: 0,
          pf: 0,
          pts: 0,
          registered_teams: registered_teams,
          team: "",
          wons: 0,
          _id: "",
        };
        const resa = await axios.post(
          `${env?.API_ENDPOINT_URL}/settings/getregisteredteamsleague`,
          { league: leagueid, tier: "All", season }
        );
        const resb = await axios.post(
          `${env?.API_ENDPOINT_URL}/settings/getregisteredteams`,
          {
            league: leagueid,
            tier: tier === "registered" ? "All" : tier,
            season,
          }
        );
        resb?.data?.forEach((item) => {
          registered_teams.push(item?._id);
          registered_teamss.push(item);
          registered_teamssr.push(item);
          registered_teamsr.push(item?._id);
        });
        console.log(
          registered_teamsr,
          "registered_teamsrregistered_teamsr608",
          registered_teamssr
        );
        resa?.data?.forEach((item) => {
          if (!registered_teamsr.includes(item?._id)) {
            registered_teamsr.push(item?._id);
            registered_teamssr.push(item);
          }
        });
        console.log(
          registered_teamsr,
          "registered_teamsrregistered_teamsr615",
          registered_teamssr
        );
        if (tier === "All") {
          console.log("got to intial check", registered_teams, res?.data);
          const notincluded = [];
          for (let i = 0; i < registered_teams.length; i++) {
            let bool = true;
            for (let j = 0; j < res?.data?.length; j++) {
              if (registered_teams[i] === res?.data?.[j].team) {
                bool = false;
              }
            }
            if (bool) {
              // console.log(teamsInfo?.[0],teamsInfo?.[0])
              // const data = teamsInfo.find(item=>item?._id===registered_teams?.[i])
              if (Boolean(registered_teamss?.[i]?.name)) {
                notincluded.push({
                  ...cloneDummy,
                  team: registered_teams?.[i],
                  ...registered_teamss?.[i],
                });
              }
            }
          }
          setTotalleaguedata([...res?.data, ...notincluded]);
          settableData([...res?.data, ...notincluded]);
          setRankingDataSource([...res?.data, ...notincluded]);
          setloader(false);
        } else if (tier === "registered") {
          console.log("got to intial check", registered_teamsr, res?.data);
          const notincluded = [];
          for (let i = 0; i < registered_teamsr.length; i++) {
            let bool = true;
            for (let j = 0; j < res?.data?.length; j++) {
              if (registered_teamsr[i] === res?.data?.[j].team) {
                bool = false;
              }
            }
            if (bool) {
              // console.log(teamsInfo?.[0],teamsInfo?.[0])
              // const data = teamsInfo.find(item=>item?._id===registered_teams?.[i])
              if (Boolean(registered_teamss?.[i]?.name)) {
                notincluded.push({
                  ...cloneDummy,
                  team: registered_teams?.[i],
                  ...registered_teamss?.[i],
                });
              }
            }
          }
          setTotalleaguedata([...res?.data, ...notincluded]);
          settableData([...res?.data, ...notincluded]);
          setRankingDataSource([...registered_teamssr]);
          setloader(false);
        } else {
          const data = res?.data.filter((item) =>
            item?.registered_teams.includes(item?.team)
          );
          const notincluded = [];
          for (let i = 0; i < registered_teams.length; i++) {
            let bool = true;
            for (let j = 0; j < data?.length; j++) {
              if (registered_teams[i] === data?.[j].team) {
                bool = false;
              }
            }
            if (bool) {
              //const datas = teamsInfo.find(item=>item?._id===registered_teams?.[i])
              if (Boolean(registered_teamss?.[i]?.name)) {
                notincluded.push({
                  ...cloneDummy,
                  team: registered_teams?.[i],
                  ...registered_teamss?.[i],
                });
              }
            }
          }
          setTotalleaguedata([...data, ...notincluded]);
          settableData([...data, ...notincluded]);
          setRankingDataSource([...data, ...notincluded]);
          setloader(false);
        }
        // setTotalleaguedata([...res?.data]);
        // settableData([...res?.data]);
        // setRankingDataSource([
        //   ...data
        // ]);
      })
      .catch((err) => console.log(err, "error Occured"));
  };
  useEffect(() => {
    if (season && tier && league) {
      getSeasonStandingsbyTier();
    }
  }, [season, league, tier]); //season,tier

  const [allLeagueListasObject, setAllLeagueListasObject] = useState({});

  const loadTeamsInfo = async () => {
    const response = await axios.get(
      `${env?.API_ENDPOINT_URL}/settings/get-teams-logo`
    );
    setteamsInfo(response?.data?.teamsInfo);
  };
  useEffect(() => {
    if (!Boolean(teamsInfo?.[0])) {
      loadTeamsInfo();
    }
  }, []);
  const leagueStyle = isMobile
    ? {
        border: "0px solid black",
        padding: "5px 0px",
        margin: "0px -18px",
        backgroundColor: "white",
        borderRadius: "0px",
      }
    : {
        border: "3px solid black",
        padding: "5px",
        backgroundColor: "white",
      };

  const scoreColumnsBasicPlayer = [
    {
      title: (
        <div className={isMobile ? styles.mobilename : styles.mobiledesk}>
          <span className={styles.ranking}>#</span> <span>Name</span>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: isMobile ? "100px" : "150px",
      //width:isMobile?'30px':'30px',
      render: (text, record, key) => (
        <div
          className={
            isMobile ? styles.mobilenamecontainer : styles.desktopnamecontainer
          }
        >
          <span className={styles.rank}>{record.rank + 1}</span>
          <span className={styles.name}>
            <Tooltip title={record?.name}>
              <img
                src={
                  Boolean(record?.logo)
                    ? `${
                        process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                          "tcbl"
                        )
                          ? "https://tcblbasketball.s3.ca-central-1.amazonaws.com"
                          : "https://leagueofchamps.s3.ca-central-1.amazonaws.com"
                      }${record?.logo}`
                    : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                        "tcbl"
                      )
                    ? log
                    : loc
                }
                height="15px"
                width="15px"
                alt="img"
              />
            </Tooltip>
          </span>
          <Tooltip
            title={
              <span>
                {record?.first_name} {record?.last_name}
              </span>
            }
          >
            {record?.first_name} {record?.last_name}
          </Tooltip>
        </div>
      ),
    },
    {
      title: <span>GP</span>,
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div>
          <span className={styles.statsjersey}>
            <span className={styles.hashtag}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          GP
        </span>
      ),
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          W
        </span>
      ),
      dataIndex: "w",
      key: "w",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          L
        </span>
      ),
      dataIndex: "l",
      key: "l",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    isMobile
      ? {
          title: <span className={styles.tier}>Tier</span>,
          dataIndex: "tier",
          key: "tier",
          render: (record, stats) => (
            <div className={isMobile ? styles.mobilestatsgp : undefined}>
              {tier}
            </div>
          ),
        }
      : { width: "0px" },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          PTS
        </span>
      ),
      dataIndex: "pts",
      key: "pts",
      width: isMobile ? "80px" : "50px",
      sorter: (a, b) => a.pts - b.pts,
      render: (record, stats) => (
        <div className={isMobile ? styles.mobilestatsgp : undefined}>
          {parseFloat(record).toFixed(0)}
        </div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FGM
        </span>
      ),
      dataIndex: "fgm",
      width: isMobile ? "70px" : "50px",
      key: "fgm",
      sorter: (a, b) => a.fgm - b.fgm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record.fgm).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FGA
        </span>
      ),
      dataIndex: "fga",
      width: isMobile ? "70px" : "50px",
      key: "fga",
      sorter: (a, b) => a.fga - b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record.fga).toFixed(0)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FG%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a.fgm / a.fga - b.fgm / b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["fga"])
              ? parseFloat((record["fgm"] / record["fga"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          2PM
        </span>
      ),
      width: isMobile ? "70px" : "50px",
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] - b["2ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["2ptm"])
              ? parseFloat(record["2ptm"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          2PA
        </span>
      ),
      dataIndex: "2pta",
      width: isMobile ? "70px" : "50px",
      key: "2pta",
      sorter: (a, b) => a["2ta"] - b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat(record["2pta"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2P%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          3PM
        </span>
      ),
      width: isMobile ? "70px" : "50px",
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] - b["3ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["3ptm"])
              ? parseFloat(record["3ptm"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          3PA
        </span>
      ),
      dataIndex: "3pta",
      width: isMobile ? "70px" : "50px",
      key: "3pta",
      sorter: (a, b) => a["3pta"] - b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat(record["3pta"]).toFixed(0)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3P%</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FTM
        </span>
      ),
      dataIndex: "ftm",
      key: "ftm",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record.ftm).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          FTA
        </span>
      ),
      dataIndex: "fta",
      width: isMobile ? "70px" : "50px",
      key: "fta",
      sorter: (a, b) => a.fta - b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record["fta"]).toFixed(0)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FT%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a.ftm / a.fta - b.ftm / b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["fta"])
              ? parseFloat((record["ftm"] / record["fta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          REB
        </span>
      ),
      dataIndex: "reb",
      key: "reb",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.reb - b.reb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          OREB
        </span>
      ),
      dataIndex: "oreb",
      key: "oreb",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.oreb - b.oreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          DREB
        </span>
      ),
      dataIndex: "dreb",
      key: "dreb",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          AST
        </span>
      ),
      dataIndex: "ast",
      key: "ast",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.ast - b.ast,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          STL
        </span>
      ),
      dataIndex: "stl",
      key: "stl",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.stl - b.stl,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          BLK
        </span>
      ),
      dataIndex: "blk",
      key: "blk",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.blk - b.blk,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          PF
        </span>
      ),
      dataIndex: "pf",
      key: "pf",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.pf - b.pf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record ?? 0).toFixed(0) ?? 0}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          TF
        </span>
      ),
      dataIndex: "tf",
      key: "tf",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.tf - b.tf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record ?? 0).toFixed(0) ?? 0}
          </div>
        ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          TO
        </span>
      ),
      dataIndex: "to",
      key: "to",
      width: isMobile ? "70px" : "50px",
      sorter: (a, b) => a.to - b.to,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mob : undefined}>
            {parseFloat(record).toFixed(0)}
          </div>
        ),
    },
  ];
  const scoreColumnsBasicPlayerAverage = [
    {
      title: (
        <div className={isMobile ? styles.mobilename : styles.mobiledesk}>
          <span className={styles.name}>#</span> <span>Name</span>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: isMobile ? "100px" : "150px",
      //width:isMobile?'30px':'30px',
      render: (text, record, key) => (
        <div
          className={
            isMobile ? styles.mobilenamecontainer : styles.desktopnamecontainer
          }
        >
          <span className={styles.rank}>{key + 1}</span>
          <span className={styles.ranking}>
            <Tooltip title={record?.name}>
              <img
                src={
                  Boolean(record?.logo)
                    ? `${
                        process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                          "tcbl"
                        )
                          ? "https://tcblbasketball.s3.ca-central-1.amazonaws.com"
                          : "https://leagueofchamps.s3.ca-central-1.amazonaws.com"
                      }${record?.logo}`
                    : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                        "tcbl"
                      )
                    ? log
                    : loc
                }
                height="15px"
                width="15px"
                alt="img"
              />
            </Tooltip>
          </span>
          <Tooltip
            title={
              <span>
                {record?.first_name} {record?.last_name}
              </span>
            }
          >
            {record?.first_name} {record?.last_name}
          </Tooltip>
        </div>
      ),
    },
    {
      title: <span>GP</span>,
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div>
          <span className={styles.statsjersey}>
            <span className={styles.hashtag}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: (
        <span>
          <div
            className={isMobile ? styles.mobiletableheader : styles.tier}
          ></div>
          GP
        </span>
      ),
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          W
        </span>
      ),
      dataIndex: "w",
      key: "w",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>{record}</div>
      ),
    },
    {
      title: (
        <span
          className={
            isMobile ? styles.mobiletableheader : styles.desktoptableheader
          }
        >
          L
        </span>
      ),
      dataIndex: "l",
      key: "l",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div className={isMobile ? styles.mob : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    isMobile
      ? {
          title: <span className={styles.tier}>Tier</span>,
          dataIndex: "tier",
          key: "tier",
          render: (record, stats) => (
            <div className={isMobile ? styles.mob : undefined}>{tier}</div>
          ),
        }
      : { width: "0px" },
    {
      title: <span className={styles.tier}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      sorter: (a, b) => a.pts / a.gp - b.pts / b.gp,
      defaultSortOrder: "ascend",
      render: (record, stats) => (
        <div className={isMobile ? styles.mobilestatsgp : undefined}>
          {parseFloat(record / stats.gp).toFixed(2)}
        </div>
      ),
    },
    {
      title: <span className={styles.tier}>FGM</span>,
      dataIndex: "fgm",
      width: "70px",
      key: "fgm",
      sorter: (a, b) => a.fgm / a.gp - b.fgm / b.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.fgm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FGA</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a.fga / a.gp - b.fga / b.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.fga ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FG%</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a.fgm / a.fga - b.fgm / b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2PM</span>,
      width: "70px",
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] / a.gp - b["2ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2ptm"])
              ? parseFloat(record["2ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2PA</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2pta"] / a.gp - b["2pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat(record["2pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>2P%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / a["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3PM</span>,
      width: "70px",
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] / a.gp - b["3ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3ptm"])
              ? parseFloat(record["3ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3PA</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3pta"] / a.gp - b["3pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div sclassName={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat(record["3pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>3P%</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / a["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: "70px",
      sorter: (a, b) => a.ftm / a.gp - b.ftm / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.ftm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FTA</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.fta / a.gp - b.fta / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record?.fta ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>FT%</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a["ftm"] / a["fta"] - b["ftm"] / a["fta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: "70px",
      sorter: (a, b) => a.oreb / a.gp - b.oreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: "70px",
      sorter: (a, b) => a.dreb / a.gp - b.dreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      sorter: (a, b) => a.reb / a.gp - b.reb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      sorter: (a, b) => a.ast / a.gp - b.ast / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      sorter: (a, b) => a.stl / a.gp - b.stl / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      sorter: (a, b) => a.blk / a.gp - b.blk / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>TO</span>,
      dataIndex: "to",
      key: "to",
      sorter: (a, b) => a.to / a.gp - b.to / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      sorter: (a, b) => a.pf / a.gp - b.pf / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.tier}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      sorter: (a, b) => a.tf / a.gp - b.tf / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.mobilestatsgp : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
  ];
  const scoreColumnsPlayer = [
    {
      title: (
        <div className={isMobile ? styles.stage : styles.dataindex}>Player</div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: isMobile ? "100px" : "150px",
      key: "name",
      render: (record, stats) => (
        <div
          onClick={() => getteamplayerstats(stats.team)}
          className={isMobile ? styles.property : styles.module}
        >
          {stats.first_name} {stats.last_name}
        </div>
      ),
    },
    {
      title: <span>#</span>,
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "70px" : "70px",
      render: (record, stats) => (
        <div>
          <span className={styles.stagemob}>
            <span className={styles.point}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>GP</span>,
      dataIndex: "gp",
      key: "gp",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>W</span>,
      dataIndex: "w",
      key: "w",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>L</span>,
      dataIndex: "l",
      key: "l",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      sorter: (a, b) => a.pts - b.pts,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>FGM</span>,
      dataIndex: "fgm",
      width: "70px",
      key: "fgm",
      sorter: (a, b) => a.fgm - b.fgm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fgm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FGA</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a.fga - b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fga}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FG%</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a?.fgm / a?.fga - b?.fgm / b?.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PM</span>,
      width: "70px",
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] - b["2ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2ptm"]) ? record["2ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PA</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2pta"] - b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"]) ? record["2pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2P%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / a["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PM</span>,
      width: "70px",
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] - b["3ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3ptm"]) ? record["3ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PA</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3pta"] - b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"]) ? record["3pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3P%</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: "70px",
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.ftm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTA</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.fta - b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fta}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FT%</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.ftm / b.fta - b.ftm / b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: "70px",
      sorter: (a, b) => a.oreb - b.oreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: "70px",
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      sorter: (a, b) => a.reb - b.reb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      sorter: (a, b) => a.ast - b.ast,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      sorter: (a, b) => a.stl - b.stl,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      sorter: (a, b) => a.blk - b.blk,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>TO</span>,
      dataIndex: "to",
      key: "to",
      sorter: (a, b) => a.to - b.to,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      sorter: (a, b) => a.pf - b.pf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      sorter: (a, b) => a.tf - b.tf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
  ];
  const scoreColumnsPlayerAverage = [
    {
      title: (
        <div className={isMobile ? styles.stage : styles.dataindex}>Player</div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: isMobile ? "100px" : "150px",
      key: "name",
      render: (record, stats) => (
        <div
          onClick={() => getteamplayerstats(stats.team)}
          className={isMobile ? styles.property : styles.module}
        >
          {stats.first_name} {stats.first_name}
        </div>
      ),
    },
    {
      title: <span>GP</span>,
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? "70px" : "50px",
      render: (record, stats) => (
        <div>
          <span className={styles.stagemob}>
            <span className={styles.point}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>GP</span>,
      dataIndex: "gp",
      key: "gp",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>W</span>,
      dataIndex: "w",
      key: "w",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>L</span>,
      dataIndex: "l",
      key: "l",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      sorter: (a, b) => a.pts / a.gp - b.pts / a.gp,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {parseFloat(record / stats.gp).toFixed(2)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>FGM</span>,
      dataIndex: "fgm",
      width: "70px",
      key: "fgm",
      sorter: (a, b) => a.fgm / a.gp - b.fgm / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fgm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FGA</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a.fga / a.gp - b.fga / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fga ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FG%</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PM</span>,
      width: "70px",
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] / a.gp - b["2ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2ptm"])
              ? parseFloat(record["2ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PA</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2pta"] / a.gp - b["2pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat(record["2pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2P%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PM</span>,
      width: "70px",
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] / a.gp - b["3ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3ptm"])
              ? parseFloat(record["3ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PA</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3pta"] / a.gp - b["3pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat(record["3pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3P%</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: "70px",
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.ftm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTA</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.fta - b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fta ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FT%</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a["ftm"] / a["fta"] - b["ftm"] / b["fta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: "70px",
      sorter: (a, b) => a.oreb / a.gp - b.oreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: "70px",
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      sorter: (a, b) => a.reb / a.gp - b.reb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      sorter: (a, b) => a.ast / a.gp - b.ast / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      sorter: (a, b) => a.stl / a.gp - b.stl / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      sorter: (a, b) => a.blk / a.gp - b.blk / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TO</span>,
      dataIndex: "to",
      key: "to",
      sorter: (a, b) => a.to / a.gp - b.to / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      sorter: (a, b) => a.pf - b.pf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      sorter: (a, b) => a.tf - b.tf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
  ];
  // const getregisteredleaders = async () => {
  //   const leagueid = leaguesList?.find((item) => item.name === league);
  //   const datab = await apiClient.post(ApiConfig.common.getLeadersDraft, {
  //     curseason: season,
  //     season: season,
  //     league: leagueid,
  //     tier: "All",
  //     leaderstype: "pts",
  //   });
  // };
  const [teamorder, setteamorder] = useState({});
  // const getteams = async ()=>{
  //   const datac = await apiClient.post(ApiConfig.common.getregisteredteamsnew,{season:season,league:league})

  //   const obja={}
  //   datac?.data?.orderInfo?.[0]?.registered_teams_order?.forEach(item=>{
  //     obja[item.team]=item.order
  //   })
  //   setteamorder({...obja})
  // }
  // useEffect(()=>{
  //   getteams()
  //   },[])
  console.log(draft, "draftdraft");
  return (
    <div style={leagueStyle} className={styles.container}>
      <div style={{ minHeight: "330px", padding: "20px 20px" }}>
        <Filter
          leaguesList={leaguesList}
          setLeaguesList={setLeaguesList}
          league={league}
          setLeague={setLeague}
          season={season}
          setSeason={setSeason}
          seasonList={seasonList}
          setSeasonList={setSeasonList}
          tier={tier}
          setTier={setTier}
          tiersList={tiersList}
          setTiersList={setTiersList}
          allLeagueListasObject={allLeagueListasObject}
          setAllLeagueListasObject={setAllLeagueListasObject}
          current={current}
          isDraft={isDraft}
          setIsDraft={setIsDraft}
          setDraft={setDraft}
          draft={draft}
        />

        <div style={isMobile ? { margin: "20px 0px" } : { margin: "20px 0px" }}>
          {current === "standings" &&
            (tier === "registered" ? (
              <>
                {isDraft ? ( //(draft?.isDraft)
                  <Tabs>
                    <Tabs.TabPane key="1" tab="Teams">
                      <Table
                        className={styles.customTable}
                        dataSource={[...rankingDataSource]
                          ?.map((e) => ({
                            ...e,
                            ...e?.standings,
                          }))
                          ?.sort((a, b) => {
                            return teamorder[a._id] > teamorder[b._id] ? -1 : 0;
                          })}
                        columns={standingsColumnsregistered}
                        pagination={false}
                        scroll={
                          isMobile
                            ? {
                                y: 400,
                              }
                            : { y: 500 }
                        }
                      />
                    </Tabs.TabPane>
                    {/* todo */}
                    <Tabs.TabPane key="2" tab="Player">
                      <Table
                        className={styles.customTable}
                        dataSource={[...playersRegistered]}
                        columns={standingsColumnsregisteredplayers}
                        pagination={false}
                        scroll={
                          isMobile
                            ? {
                                y: 400,
                              }
                            : { y: 500 }
                        }
                      />
                    </Tabs.TabPane>
                  </Tabs>
                ) : (
                  <Table
                    className={styles.customTable}
                    dataSource={[...rankingDataSource]?.map((e) => ({
                      ...e,
                      ...e?.standings,
                    }))}
                    columns={standingsColumnsregistered}
                    pagination={false}
                    scroll={
                      isMobile
                        ? {
                            y: 400,
                          }
                        : { y: 500 }
                    }
                  />
                )}
              </>
            ) : (
              <Table
                className={styles.customTable}
                dataSource={[...rankingDataSource]?.map((e) => ({
                  ...e,
                  ...e?.standings,
                }))}
                loading={loader}
                columns={standingsColumns}
                pagination={false}
                scroll={
                  isMobile
                    ? {
                        y: 400,
                      }
                    : { y: 500 }
                }
              />
            ))}
        </div>
      </div>
      <Modal
        width={isMobile ? "100%" : "65%"}
        footer={null}
        style={isMobile ? { maxWidth: "calc(100vw - 10px)" } : undefined}
        open={showPlayerstats}
        onCancel={() => setshowPlayerstats(false)}
      >
        <div className={styles.container}>
          <Table
            className={styles.customTable}
            columns={scoreColumnsPlayer}
            dataSource={playerStats?.map((item, index) => {
              return { ...item, rank: index };
            })}
            scroll={
              isMobile
                ? {
                    marginLeft: "-10px",
                  }
                : {
                    y: 500,
                    x: 1600,
                  }
            }
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
};
export default Standings;
